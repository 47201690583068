img.footer_img_logo {
  width: 50%;
}
p.footer_logo_para {
  font-weight: 800;
  color: #ffff;
  font-size: 40px;
}
li.li_list {
  list-style: none;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 40px;
  color: #ffff;
  text-transform: uppercase;
  line-height: 49px;
}
.footer_soical_icons {
  margin-top: 30px;
}
a.link {
  color: #ffff;
  text-decoration: none;
}
.footer_masterpiece.my-5 {
  margin-left: 50px;
}
p.masterpice {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}
.footer_content .btn-danger {
  background-color: #f70d0c;
  font-weight: 700;
  margin-left: 40px;
}
h3.join_button.get_start {
  font-size: 30px;
}
.row.footer_content {
  margin: 7rem 0px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  img.footer_img_logo {
    width: 50%;
  }
  p.footer_logo_para {
    font-weight: 800;
    color: #ffff;
    font-size: 40px;
  }
  li.li_list {
    list-style: none;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 32px;
    color: #ffff;
    text-transform: uppercase;
    line-height: 49px;
  }
  .footer_soical_icons {
    margin-top: 30px;
  }
  a.link {
    color: #ffff;
    text-decoration: none;
  }
  .footer_masterpiece.my-5 {
    margin-left: 50px;
  }
  p.masterpice {
    color: #fff;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
  .footer_content .btn-danger {
    background-color: #f70d0c;
    font-weight: 700;
    margin-left: 40px;
  }
  h3.join_button.get_start {
    font-size: 30px;
  }
  .row.footer_content {
    margin: 7rem 0px;
  }
}