.bottom-nav {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 10px;
  background-color: #111111;
}
