.global_header{
    align-items: center;
    font-family: Inter;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%);
}
.global_header span {
  font-size: 50px;
  margin-right: 25px;
  color: #f70d0c;
  font-weight: bold;
}
.global_header h2 {
  font-size: 90px;
  font-weight: 900;
}
.global_header p{
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}
.global_header .btn{
  font-size: 40px;
}
.text-danger{
  color: #F70D0C !important;
}
.fs-40{
  font-size: 40px;
}
.fs-50{
  font-size: 50px;
}
.fs-65{
  font-size: 65px;
}
.fs-90{
  font-size: 90px;
}
.bg-danger{
  background-color: #F70D0C !important;
}

/* Margins */
.my-50{
  margin-top: 50px;
  margin-bottom: 50px;
}
.my-100{
  margin-top: 100px;
  margin-bottom: 100px;
}
.my-150{
  margin-top: 150px;
  margin-bottom: 150px;
}
.my-200{
  margin-top: 200px;
  margin-bottom: 200px;
}
.my-250{
  margin-top: 250px;
  margin-bottom: 250px;
}
.my-300{
  margin-top: 300px;
  margin-bottom: 300px;
}
.mt-50{
  margin-top: 50px;
}
.mt-100{
  margin-top: 100px;
}
.mt-150{
  margin-top: 150px;
}
.mt-200{
  margin-top: 200px;
}
.mt-250{
  margin-top: 250px;
}
.mt-300{
  margin-top: 300px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-100{
  margin-bottom: 100px;
}
.mb-150{
  margin-bottom: 150px;
}
.mb-200{
  margin-bottom: 200px;
}
.mb-250{
  margin-bottom: 250px;
}
.mb-300{
  margin-bottom: 300px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .global_header h2 {
    font-size: 50px;
  }
  .global_header span {
    font-size: 30px;
  }
  .global_header p {
    font-size: 20px;
  }
  .global_header .btn {
    font-size: 24px;
  }
}
@media screen and (max-width: 576px) {
  .global_header h2 {
    font-size: 40px;
  }
  .global_header span {
    font-size: 20px;
  }
  .global_header p {
    font-size: 16px;
  }
  .global_header .btn {
    font-size: 20px;
    width: 80%;
  }
}