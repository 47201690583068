.navbar{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
a.textlight {
  color: #fff;
  text-decoration: none;
}

a.active.active {
  color: #f70d0c;
  text-decoration: none;
}
